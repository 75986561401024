import React, { useState, useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';


export const SurveyData = ({ region, sector, debug }) => {
    const [needsAPIData, setNeedsAPIData] = useState(false);

    const [noCanDo, setNoCanDo] = useState(false);

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    //const [firstLoad, setFirsLoad] = useState(true);

    const [isCounted, setIsCounted] = useState(false);
    const [items, setItems] = useState({});
    const [filteredItems, setFilteredItems] = useState({});
    var item;
    var filtered = {};
    const [avs, setAvs] = useState({});
    const [absValues, setAbsValues] = useState({});
    const [absValuesArr, setAbsValuesArr] = useState([]);
    const [countAll, setCountAll] = useState(0);

    const opts = { minimumFractionDigits: 0 };


    const urls = {
        all: 'https://api.retool.com/v1/workflows/5b5c022a-53e6-4bf8-8d53-58d8bbfdb88a/startTrigger?workflowApiKey=retool_wk_22e6fabd57fa485c9bb1cf9dee1a003c&cache_bust='+Date.now(),
        markets: 'https://api.retool.com/v1/workflows/29daa3d8-117b-4108-8e11-34d0a9df41da/startTrigger?workflowApiKey=retool_wk_2183d704cad646dd8f24ff39937df0bb&cache_bust='+Date.now(),
        leadership: 'https://api.retool.com/v1/workflows/0da33822-ca61-4859-b955-edf51eaabcde/startTrigger?workflowApiKey=retool_wk_e2341752af4948cdb8b14d8c2e75461a&cache_bust='+Date.now(),
        finance: 'https://api.retool.com/v1/workflows/2d3449d1-8a0c-4925-8205-640b784ff20a/startTrigger?workflowApiKey=retool_wk_581a3c8018994a4ab8e5790f60ba32b9&cache_bust='+Date.now(),
        talent: 'https://api.retool.com/v1/workflows/bc43d322-9288-4623-83ac-fabf3aad2561/startTrigger?workflowApiKey=retool_wk_bd5be97cb63049dabab82d135a6d5c5e&cache_bust='+Date.now(),
        infrasructure: 'https://api.retool.com/v1/workflows/efe8d600-26d6-4aea-bdd5-907399ecd948/startTrigger?workflowApiKey=retool_wk_56d678bde7fa40958be0cfac8c5d83c3&cache_bust='+Date.now()
    };

    const titles = {
        markets: 'Access to Markets',
        leadership: 'Leadership Development',
        finance: 'Access to Finance',
        talent: 'Access to Talent',
        infrasructure: 'Access to Infrastructure and R&D & Innovation'
    };

    const [titlesSorted, setTitlesSorted] = useState(titles);

    const images = {
        markets: 'assets/markets-blue.svg',
        leadership: 'assets/leadership-blue.svg',
        finance: 'assets/finance-blue.svg',
        talent: 'assets/talent-blue.svg',
        infrasructure: 'assets/infrasructure-blue.svg'
    }
    /*
    const classes = {
        markets: 'text-center h-100 border-end border-secondary rounded-0',
        leadership: 'text-center h-100 border-end border-secondary rounded-0',
        finance: 'text-center h-100 border-end border-secondary rounded-0 border-xl-end',
        talent: 'text-center h-100 border-end border-secondary rounded-0',
        infrasructure: 'text-center h-100'
    }*/

    const classes = [
        'text-center h-100 border-end border-secondary rounded-0',
        'text-center h-100 border-end border-secondary rounded-0',
        'text-center h-100 border-end border-secondary rounded-0 border-xl-end',
        'text-center h-100 border-end border-secondary rounded-0',
        'text-center h-100'
    ];
//text-center h-100


    //initiate data - check local store first and call API if nothing

    useEffect(() => {
        if (window.localStorage !== undefined) {
            var data = {};
            data = window.localStorage.getItem('surveyData');
            if (data !== null) {
                var surveyData = JSON.parse(data);
                setFilteredItems(surveyData);
                setItems(surveyData);

                setIsLoaded(true);


            }
            else {
                setNeedsAPIData(true);
            }
        }
        else {
            setNeedsAPIData(true);
        }
    }, []);



    useEffect(() => {
        if (needsAPIData) {
            //console.log('loading API data');
            Object.keys(urls).map((key, index) => {
                if (!isLoaded) {
                    fetch(urls[key])
                        .then(res => res.json())
                        .then(
                            (result) => {
                                setItems(its => ({
                                    ...its,
                                    [key]: result.data
                                }));
                                setFilteredItems(fit => ({
                                    ...fit,
                                    [key]: result.data
                                }));
                            },
                            (error) => {
                                setIsLoaded(true);
                                setError(error);
                            }
                        )
                }

            })
        }

    }, [needsAPIData])

    useEffect(() => {
        if (window.localStorage.getItem('surveyData') === null) {
            if ('markets' in items && 'leadership' in items && 'finance' in items && 'talent' in items && 'infrasructure' in items) {
                //console.log('running the setter');
                localStorage.setItem('surveyData', JSON.stringify(items));
            }
        }
    }, [isLoaded]);

    //set the percentages
    useEffect(() => {
        //console.log('firing couunts');
        if (isLoaded && !error) {
            setCountAll(countItems(filteredItems['all']));
            Object.keys(filteredItems).map((key, index) => {
                //console.log(key + ': ' + countItems(filteredItems[key]));
                setAvs(avs => ({
                    ...avs,
                    [key]: (countAll > 10 ? Math.round(100 * countItems(filteredItems[key]) / countAll) + '%' : 'Insufficient data')
                }));
                setAbsValues(absValues => ({
                    ...absValues,
                    [key]: (countAll > 10 ? Math.round(100 * countItems(filteredItems[key]) / countAll)  : 0)
                }));
            });
        }

    }, [isLoaded, countAll])


    //filter by region
    useEffect(() => {
        if (region.length !== 0 && region !== 'United Kingdom') {
            if (sector.length !== 0) {
                setNoCanDo(true);
                return;
            }
            Object.keys(items).map((key, index) => {
                item = items[key];

                filtered[key] = item.filter((ite) => {

                    return ite.region == region;
                });


                setFilteredItems(its => ({
                    ...its,
                    [key]: filtered[key]
                }));
            });

            var allCount = countItems(filtered['all']);

            setCountAll(allCount);

            Object.keys(filtered).map((key, index) => {
                setAvs(avs => ({
                    ...avs,
                    [key]: (allCount > 10 ? Math.round(100 * countItems(filtered[key]) / allCount) + '%' : 'Insufficient data')
                }));
                setAbsValues(absValues => ({
                    ...absValues,
                    [key]: (allCount > 10 ? Math.round(100 * countItems(filtered[key]) / allCount)  : 0)
                }));
            });

        }
        else {
            setNoCanDo(false);
            if ('markets' in items && 'leadership' in items && 'finance' in items && 'talent' in items && 'infrasructure' in items) {
                setFilteredItems(items);

                var allCount = countItems(items['all']);

                setCountAll(allCount);

                Object.keys(items).map((key, index) => {
                    setAvs(avs => ({
                        ...avs,
                        [key]: (allCount > 10 ? Math.round(100 * countItems(items[key]) / allCount) + '%' : 'Insufficient data')
                    }));
                    setAbsValues(absValues => ({
                        ...absValues,
                        [key]: (allCount > 10 ? Math.round(100 * countItems(items[key]) / allCount)  : 0)
                    }));
                });


            }
        }
    }, [region]);

    //filter by sector
    useEffect(() => {
        if (sector.length !== 0) {
            if (region.length !== 0 && region !== 'United Kingdom') {
                setNoCanDo(true);
                return;
            }
            Object.keys(items).map((key, index) => {
                item = items[key];

                filtered[key] = item.filter((ite) => {

                    return ite.sector == sector;
                });


                setFilteredItems(its => ({
                    ...its,
                    [key]: filtered[key]
                }));
            });

            var allCount = countItems(filtered['all']);

            setCountAll(allCount);

            Object.keys(filtered).map((key, index) => {
                setAvs(avs => ({
                    ...avs,
                    [key]: (allCount > 10 ? Math.round(100 * countItems(filtered[key]) / allCount) + '%' : 'Insufficient data')
                }));
                setAbsValues(absValues => ({
                    ...absValues,
                    [key]: (allCount > 10 ? Math.round(100 * countItems(filtered[key]) / allCount)  : 0)
                }));
            });

        }
        else {
            setNoCanDo(false);
            if ('markets' in items && 'leadership' in items && 'finance' in items && 'talent' in items && 'infrasructure' in items) {
                setFilteredItems(items);

                var allCount = countItems(items['all']);

                setCountAll(allCount);

                Object.keys(items).map((key, index) => {
                    setAvs(avs => ({
                        ...avs,
                        [key]: (allCount > 10 ? Math.round(100 * countItems(items[key]) / allCount) + '%' : 'Insufficient data')
                    }));
                    setAbsValues(absValues => ({
                        ...absValues,
                        [key]: (allCount > 10 ? Math.round(100 * countItems(items[key]) / allCount)  : 0)
                    }));
                });


            }
        }
    }, [sector]);



    //count list of items
    var countItems = (list) => {
        if (typeof list !== 'undefined' && list.length) {
            return list.map(item => parseInt(item.count)).reduce((a, b) => a + b);
        }
        else {
            return 0;
        }

    }


    useEffect(() => {
        //make sure all values have loaded
        if ('markets' in filteredItems && 'leadership' in filteredItems && 'finance' in filteredItems && 'talent' in filteredItems && 'infrasructure' in filteredItems) {
            setIsLoaded(true);
        }

        if ('markets' in avs && 'leadership' in avs && 'finance' in avs && 'talent' in avs && 'infrasructure' in avs) {
            setIsCounted(true);
        }
    });

    useEffect(() => {
        if(isCounted){
            var absValuesArr = [];
            Object.keys(absValues).forEach((i) => {
                var obj = {};
                if (i !== 'all'){
                    obj.name = i;
                    obj.value = absValues[i];
                    absValuesArr.push(obj);
                }
                
            });
            absValuesArr.sort((a,b) => b.value - a.value);
            setAbsValuesArr(absValuesArr);

        }
        
    },[avs]);


    const refreshData = () => {
        setItems({});
        setFilteredItems({});
        setIsLoaded(false);

        //delete local store
        localStorage.removeItem("surveyData");
        setNeedsAPIData(true); //call api
    };





    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Scaleups</span> Top Barriers to Further Growth</div>
                    <span className="source">Source: 2023 ScaleUp Survey</span>
                </h2>
                <Spinner animation="border" variant="primary" />
                <p>Loading data...</p>
            </div>
        );
    }
    else if (isCounted && absValuesArr.length) {

        return (

            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Scaleups</span> Top Barriers to Further Growth</div>
                    <span className="source">Source: 2023 ScaleUp Survey</span>
                </h2>
                {noCanDo &&
                    <p className="error">This data cannot be filtered by both region and sector.</p>
                }
                {!noCanDo &&
                    <Row className="g-0 align-items-center justify-content-center mb-4 row-cols-2 row-cols-md-3 row-cols-xl-5 gy-3">
                        {absValuesArr.map((v, index) => {
                           
                                return (
                                    <Col key={index}>
                                        <Card text="dark" className={classes[index]}>
                                            <Card.Body>
                                                <Icon src={images[v.name]} title={titles[v.name]} />
                                                <Card.Title className="px-3">{titles[v.name]}</Card.Title>
                                                <Card.Text className="text-blue">
                                                    {avs[v.name]}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            

                        })}


                    </Row>
                }
            </div>

        );
    }



}

function Icon({src, title}){
    return (
        <img src={src} alt={title} />
    )
}