import React, { useState, useEffect } from 'react';
import {BarChart} from "./BarChart";
import Spinner from 'react-bootstrap/Spinner';

export const Clusters = ({ region, sector, debug }) => {
    const url = 'https://api.retool.com/v1/workflows/fc20ba63-d466-4739-9906-11e8476a23db/startTrigger?workflowApiKey=retool_wk_69d9057585564cacac47d94483afb73f&cache_bust='+Date.now();
    const [error, setError] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);


    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [chartData, setChartData] = useState({});
      
    var filtered;


    useEffect(() => {
        if (region.length !== 0) {
            filtered = items.filter((item) => item.region === region);
            setChartData(getChartData(filtered));
        }
        else {
            if (!firstLoad){
                filtered = items.filter((item) => item.region === 'United Kingdom');
                setChartData(getChartData(filtered));
            } 
        }

    }, [region]);



    const getChartData = (data) => {
        //loop through the data and create a data object that is good for Chart.js
        if (data.length === 0){
            return {
                labels: ['No data'],
                datasets: [
                    {
                        data: [0],
                    },
                ],
            };
        }


        var labels = [];
        for (let i = 0; i < 17; i++){
            var label = data[i].sector;
            if (label !== null){
                labels.push(label);
            }
        }

        //create an array of chart values

        var chartPairs = [];
        labels.forEach((item) =>  {
            var thisSector = data.filter(dataItem => dataItem.sector === item );
            var count = thisSector.length;
            var average = thisSector.map(it => it.proportion).reduce((a, b) => a + b) / count;
            chartPairs.push([item, average]);
        });

        //sort the values in descending order.
        chartPairs.sort((a,b) => {
            return b[1] - a[1];
        });

        var sortedLabels = [];
        var sortedValues = [];

        //split into separate arrays again
        chartPairs.forEach((value) => {
            sortedLabels.push(value[0]);
            sortedValues.push(value[1]);
        });


        var chartData = {
            labels: sortedLabels,
            datasets: [
                {
                    data: sortedValues,
                },
            ],
        };

        return chartData;
    };


    const refreshData = () => {
        
        setIsLoaded(false);
        //delete local store
        localStorage.removeItem("clusterData");
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.data);
                    var list = result.data;
                    var uk = list.filter((item) => item.region === 'United Kingdom');
                    setChartData(getChartData(uk));
                    localStorage.setItem('clusterData', JSON.stringify(result.data));
                    setFirstLoad(false); //hack to get around [region] happening when it hasn't changed
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )


    };



    useEffect(() => {
        //check local store first
        if (window.localStorage !== undefined) {
            var clusterData = window.localStorage.getItem('clusterData');
            if (clusterData !== null){
                let parsed = JSON.parse(clusterData);
                setIsLoaded(true);
                setItems(parsed);
                var uk = parsed.filter((item) => item.region === 'United Kingdom');
                setChartData(getChartData(uk));
                setFirstLoad(false); //hack to get around [region] happening when it hasn't changed
                return;
            }
        }

        //only do this if local store is empty.
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.data);
                    var list = result.data;
                    var uk = list.filter((item) => item.region === 'United Kingdom');
                    setChartData(getChartData(uk));
                    localStorage.setItem('clusterData', JSON.stringify(result.data));
                    setFirstLoad(false); //hack to get around [region] happening when it hasn't changed
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Scaleup</span> Sector Clusters</div>
                    <span className="source">Source: ONS IDBR 2018 - 2021</span>
                </h2>
                <Spinner animation="border" variant="primary" />
                <p>Loading data...</p>
            </div>
        );
    }
    else {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Scaleup</span> Sector Clusters</div>
                    <span className="source">Source: ONS IDBR 2018 - 2021</span>
                </h2>
                <BarChart chartData={chartData} />                
            </div>
        );
    }




}