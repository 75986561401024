import React, { useState, useEffect } from 'react';
import Axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Link } from "./link.svg";

export const ScaleupProgrammes = ({ region, sector, debug }) => {
    const baseUrl = 'https://dev-scaleup-institute.pantheonsite.io';
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [countProgrammes, setCountProgrammes] = useState(0);
    const [hasData, setHasData] = useState(false);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);

    const [one, setOne] = useState([]);
    const [firmFocus, setFirmFocus] = useState([]);
    const [general, setGeneral] = useState([]);


    const [checkLocalStore, setCheckLocalStore] = useState(false);



    //check local store first
    useEffect(() => {
        if (window.localStorage !== undefined) {
            var data = {};
            data = window.localStorage.getItem('ScaleupProgrammes');
            if (data !== null) {
                var list = JSON.parse(data);
                setItems(list);
                setFilteredItems(list);
                setIsLoaded(true);
            }

        }
        setCheckLocalStore(true);
    }, []);

    //get paged data from WP API.
    useEffect(() => {
        if (checkLocalStore) {
            const fetchWPData = async () => {
                var totalPages = 1;
                for (let i = 1; i < 5; i++) {


                    if (i <= totalPages) {
                        await Axios.get(baseUrl + "/wp-json/wp/v2/programmes", {
                            params: { page: i, orderby: "title", order: "asc", per_page: "100", cache_bus: Date.now() }
                        }).then(response => {
                            var response_data = response.data;
                            setItems(items => [...items, ...response_data]);
                            setFilteredItems(filteredItems => [...filteredItems, ...response.data]);
                            setCountProgrammes(response.headers["x-wp-total"]);
                            if (i == 1) {
                                totalPages = response.headers["x-wp-totalpages"];
                            }

                        });

                    }
                    else {
                        setHasData(true);
                        break;
                    }

                }
            }
            fetchWPData();

        }

    }, [checkLocalStore]);


    useEffect(() => {
        if (region.length !== 0) {
            if (region == 'United Kingdom') {
                if (sector.length !== 0) {
                    var filtered = items.filter((item) => {
                        return item.sectors.includes(sector);
                    });
                    setCountProgrammes(filtered.length);
                    setFilteredItems(filtered);
                }
                else {
                    setFilteredItems(items);
                    setCountProgrammes(items.length);
                }
            }
            else {
                if (sector.length !== 0) {
                    var filtered = items.filter((item) => {
                        return item.sectors.includes(sector) && (item.regions.includes("UK") || item.regions.includes(region));
                    });
                    setCountProgrammes(filtered.length);
                    setFilteredItems(filtered);
                }
                else {
                    var filtered = items.filter((item) => {
                        return item.regions.includes("UK") || item.regions.includes(region);
                    });
                    setCountProgrammes(filtered.length);
                    setFilteredItems(filtered);
                }
            }

        }


    }, [region]);


    useEffect(() => {
        if (sector.length !== 0) {
            if (region == 'United Kingdom' || region.length == 0){
                var filtered = items.filter((item) => {
                    return item.sectors.includes(sector);
                });
                setCountProgrammes(filtered.length);
                setFilteredItems(filtered);
            }
            else{
                var filtered = items.filter((item) => {
                    return item.sectors.includes(sector) && (item.regions.includes("UK") || item.regions.includes(region));
                });
                setCountProgrammes(filtered.length);
                setFilteredItems(filtered);
            }
            
        }
        else {
            if (region == 'United Kingdom' || region.length == 0){
                setFilteredItems(items);
                setCountProgrammes(items.length);
            }
            else{
                var filtered = items.filter((item) => {
                    return item.regions.includes("UK") || item.regions.includes(region);
                });
                setCountProgrammes(filtered.length);
                setFilteredItems(filtered);
            }
        }


    }, [sector]);





    useEffect(() => {
        if (hasData) {
            //split data into 3 lists (4 later)
            var oneToWatch = filteredItems.filter(item => {
                return item.types.includes("ScaleUp Institute Endorsed/One to Watch");
            });
            var scalingFirmFocus = filteredItems.filter(item => {
                return item.types.includes("Scaling firm focus");
            });
            var generalScaling = filteredItems.filter(item => {
                return item.types.includes("General with scaling elements");
            });
            setOne(oneToWatch);
            setFirmFocus(scalingFirmFocus);
            setGeneral(generalScaling);

            setIsLoaded(true);
        }

    }, [hasData, filteredItems]);



    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Scaleup</span> Programmes</div>
                    <Button variant="outline-secondary" onClick={(e) => {
                        e.preventDefault();
                        window.open('https://www.scaleupinstitute.org.uk/find-programmes/', '_blank');
                    }}>Support Finder</Button>
                </h2>
                <Spinner animation="border" variant="primary" />
                <p>Loading data...</p>
            </div>
        );
    }
    else {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">{countProgrammes}</span> Scaleup Programmes</div>
                    <Button variant="outline-secondary" onClick={(e) => {
                        e.preventDefault();
                        window.open('https://www.scaleupinstitute.org.uk/find-programmes/', '_blank');
                    }}>Support Finder</Button>
                </h2>
                <div className="pre-scrollable">
                    <Tabs
                        defaultActiveKey="one"
                        id="programme-tabs"
                        className="mb-2"
                    >
                        <Tab eventKey="one" title="SUI endorsed / one to watch">
                            <ul>
                                {one.map(d => (
                                    <li className="d-flex justify-content-between align-items-center" key={d.id}>
                                        <a href={d.link} target="_blank" className="prog-name-link"><span className="prog-name" dangerouslySetInnerHTML={{ __html: d.title.rendered }} /></a>
                                        <div className="meta d-flex w-30 justify-content-between align-items-center">
                                            <div className="theme">{d.themes[0]}</div>
                                            <div className="link"><a href={d.link} target="_blank"><Link /></a></div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Tab>
                        <Tab eventKey="focus" title="Scaling firm focus">
                            <ul>
                                {firmFocus.map(d => (
                                    <li className="d-flex justify-content-between align-items-center" key={d.id}>
                                        <a href={d.link} target="_blank" className="prog-name-link"><span className="prog-name" dangerouslySetInnerHTML={{ __html: d.title.rendered }} /></a>
                                        <div className="meta d-flex w-30 justify-content-between align-items-center">
                                            <div className="theme">{d.themes[0]}</div>
                                            <div className="link"><a href={d.link} target="_blank"><Link /></a></div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Tab>
                        <Tab eventKey="general" title="General with scaling elements">
                            <ul>
                                {general.map(d => (
                                    <li className="d-flex justify-content-between align-items-center" key={d.id}>
                                        <a href={d.link} target="_blank" className="prog-name-link"><span className="prog-name" dangerouslySetInnerHTML={{ __html: d.title.rendered }} /></a>
                                        <div className="meta d-flex w-30 justify-content-between align-items-center">
                                            <div className="theme">{d.themes[0]}</div>
                                            <div className="link"><a href={d.link} target="_blank"><Link /></a></div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }

}