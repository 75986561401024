import React, { useRef, useState, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { ReactComponent as MapScaleup } from "./assets/map-scaleup.svg";

mapboxgl.accessToken = 'pk.eyJ1IjoiZW1pbGVrb3R0IiwiYSI6ImNsbm9ueWc1eTAzcjUycW56ZzV1ODJndGYifQ.JIU7bJtD2X5eZFkG_DlFgw';






const createGeoJSONFromWP = (data) => {
    var features = [];
    data.forEach((item, i) =>{

        var lng = item.location.lng ? parseFloat(item.location.lng) : null;
        var lat = item.location.lat ? parseFloat(item.location.lat) : null;

        var feature = {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    lng,
                    lat
                ]
            },
            "properties": {
                "id" : item.id,
                "title" : item.title.rendered,
                "link" : item.link,
                "categories" : item.categories,
                "desc" : item.acf.copy,
                "address" : item.location.address,
                "image" : item.image,
            }
        };
        
        if (lng !== null){
            features.push(feature);
        }
        
    });
    var geoJson = {
        "type": "FeatureCollection",
        "features": features,
    };

    return geoJson;
}




export const ProgMap = ({ region, sector, debug }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-3.8242465);
    const [lat, setLat] = useState(53.7166888);
    const [rawItems, setRawItems] = useState([]);
    const [skip, setSkip] = useState(0);
    const [loadedCount, setLoadedCount] = useState(0);
    const [apiItems, setApiItems] = useState({});
    const [zoom, setZoom] = useState(5);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(false);
    const prog_url = 'https://dev-scaleup-institute.pantheonsite.io/wp-json/wp/v2/programmes';

    



    useEffect(() => {
        setIsLoaded(true);
    },[]);
    useEffect(() => {
        if (region.length !== 0) {
            if (region == 'United Kingdom'){
                //map.current.setFilter('visible_scaleups', null);
                const mapStyle = map.current.getStyle();
                mapStyle.sources.companies.filter = null;
                map.current.setStyle(mapStyle);
            }
            else{
                //map.current.setFilter('visible_scaleups', ['in', region , ['string', ['get', 'region']]]);
                const mapStyle = map.current.getStyle();
                mapStyle.sources.companies.filter = ['in', region , ['string', ['get', 'region']]];
                map.current.setStyle(mapStyle);
            }
            
        }
    
    }, [region]);



    useEffect(() => {
    
        if (map.current) return; // initialize map only once
        if (isLoaded){
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [lng, lat],
                zoom: zoom
            });
    
            map.current.on('load', () => {
                map.current.resize();
                map.current.addControl(new mapboxgl.FullscreenControl());
                map.current.addControl(new mapboxgl.NavigationControl());
            });
        }
       
        
        
    }, [isLoaded]);
   
    useEffect(() => {
        if (isLoaded){
            map.current.on('load', () => {
                

                
                if (map.current.getLayer('visible_scaleups')){
                    return;
                }

                map.current.addSource('companies',{
                    type: 'geojson',
                    data: 'https://clients.acroweb.co.uk/scaleup/visible-scaleups-12044.json?cache='+Date.now(),
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                });

                map.current.addLayer({
                    id: 'company_clusters',
                    type: 'circle',
                    source: 'companies',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': '#FF8400',
                        'circle-stroke-color': '#fff',
                        'circle-stroke-width': 1,
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            200,
                            25,
                            1000,
                            30
                        ]
                    }
                });

                map.current.addLayer({
                    id: 'company_cluster_count',
                    type: 'symbol',
                    source: 'companies',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': ['get', 'point_count_abbreviated'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 18,
                    },
                    paint: {
                        "text-color": "#ffffff"
                      }
                });


                map.current.addLayer({
                    id: 'visible_scaleups',
                    type: 'circle',
                    source: 'companies',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': '#FF8400',
                        'circle-radius': 6,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#fff'
                    }
                });



                // inspect a cluster on click
                map.current.on('click', 'company_clusters', (e) => {
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['company_clusters']
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.current.getSource('companies').getClusterExpansionZoom(
                        clusterId,
                        (err, zoom) => {
                            if (err) return;

                            map.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                });



                map.current.on('click', 'visible_scaleups', function(e) {
                    var coordinates = e.features[0].geometry.coordinates.slice();
    

                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                    var properties = e.features[0].properties;
                    console.log(properties);
                    
                    var title = properties.title;
                    var addr = '<p>'+properties.address+'</p>';
                    var region = '<p>'+properties.region+'</p>';             
                    let sector_list = '<p>Sector keywords: '+properties.sector_list+'</p>';
                    var turnover = properties.turnover.toLocaleString('en-GB', { style: "currency", currency: "GBP" });
                    var employment = properties.employment.toLocaleString('en-GB', {});
                    var url = '';
                    if (properties.url){
                        url = `http://${properties.url}`;
                    }
                    

                    var html = `<h3>${title}</h3>`;
                   
                    if (properties.employment){
                        html += `<div class="meta-item"><h6>Employment</h6><div>${employment}</div></div>`;
                    }
                    if (properties.turnover > 0){
                        html += `<div class="meta-item"><h6>Turnover</h6><div>${turnover}</div></div>`;
                    }

                    if (url){
                        html += `<div class="website"><a href="${url}" target="_blank"><span>View website</span> <img src="assets/web-link.svg" /></a></div>`;
                    }


                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(html)
                        .addTo(map.current);
    
    
                });
    
                        
            });



        }
        
    },[isLoaded]);

    if (!isLoaded){
        return <div className="h-100 d-flex"><div className="my-auto mx-auto">Fetching data for map. {loadedCount} companies loaded so far...</div></div>;
    }
    else if (error){
        return <div>Error: {error}</div>;
    }
    else{
        return (
            <div className="h-100 position-relative map-wrapper">
                <div ref={mapContainer} className="map-container h-100" />
                <div className="map-key position-absolute">
                    <div className="key-item"><MapScaleup /> Scaleup</div>
                </div>
            </div>
        );
    }
    

   
}