export const conditonalFormatMillions = (theNumber) => {
    var result = theNumber;
    var units = ' m.';
    if (theNumber >= 1000 && theNumber < 1000000){
        //billions
        result = theNumber/1000;
        units = ' b.';
    }
    else if (theNumber >= 1000000){
        //trillions
        result = theNumber/1000000;
        units = ' t.';
    }
    return "£ " + result.toLocaleString('en-GB', {maximumFractionDigits: 2, minimumFractionDigits: 2}) + units;
}

export const conditonalFormatNumber = (theNumber) => {
    var result = theNumber;
    var units = '';
    if (theNumber < 1000000){
        units = '';
        result = theNumber;
    }
    else if (theNumber >= 1000000 && theNumber < 1000000000){
        //millions
        result = theNumber/1000000;
        units = ' m.';
    }
    else if (theNumber >= 1000000000 && theNumber < 1000000000000){
        //billions
        result = theNumber/1000000000;
        units = ' b.';
    }
    else if (theNumber >= 1000000000000){
        //trillions
        result = theNumber/1000000000000;
        units = ' t.';
    }
    return "£ " + result.toLocaleString('en-GB', {maximumFractionDigits: 2, minimumFractionDigits: 2}) + units;
}

export const refreshAllData = () => {
    const localKeys = [
        'regions',
        'sectors',
        'ONSScaleUpData',
        'scaleUpIndex',
        'surveyData',
        'clusterData'
    ];

    localKeys.forEach((item) => {
        localStorage.removeItem(item);
    });

    window.location.reload(false);
}