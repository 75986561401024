import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { conditonalFormatMillions, conditonalFormatNumber } from './Utils';
import { ReactComponent as Scaleups } from "./assets/scaleups-orange.svg";
import { ReactComponent as Employment } from "./assets/employment-orange.svg";
import { ReactComponent as Turnover } from "./assets/turnover-orange.svg";
import { ReactComponent as Grants } from "./assets/grants-orange.svg";
import { ReactComponent as Investment } from "./assets/investment-orange.svg";


export const ScaleUpIndex = ({ region, sector, debug }) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [checkLocalStore, setCheckLocalStore] = useState(false);

    const [items, setItems] = useState({});
    const [sectorItems, setSectorItems] = useState({});
    const [filteredItems, setFilteredItems] = useState({});

    const primary_url = 'https://api.retool.com/v1/workflows/55cf5976-9435-44c1-b332-5ad4b2f93615/startTrigger?workflowApiKey=retool_wk_01292beabf264941869db36ac8b06074&cache_bust='+Date.now();
    const secondary_url = 'https://api.retool.com/v1/workflows/3edc51f9-e79e-4b8a-ac4f-e45dc12e2b35/startTrigger?workflowApiKey=retool_wk_4c557c23b2174c5181ed1a44f0d1641e&cache_bust='+Date.now();

    var filtered;
    var countScaleUps = 0;
    var countEmployment = 0;
    var countTurnover = 0;
    var countGrants = 0;
    var countInvestment = 0;

    /*
        Approach: 
        Fetch primary list of data with all company details, data and regions - this would be used for ALL and filter by region only
       
        Secondarylist that just has company_id and sector (mapped)
        When filtering by sector, first filter this secondary list to get a list of company_ids as an array
        Then filter the primary list by list of company_ids

        If also filtering by region, filter this result by region afterwards.
    */

    useEffect(() => {
        if (region.length !== 0 && region !== 'United Kingdom') {
            console.log(region);
            filtered = items.filter((item) => {
                if (sector.length === 0) {
                    //simple case
                    return item.region === region;
                }
                else {
                    setIsLoaded(false);
                    //more complex - @todo:::
                    var company_ids = getCompaniesFromSector();
                    return item.region === region && company_ids.includes(item.company_id);
                }

            });
            //console.log(filtered);
            setIsLoaded(true);
            setFilteredItems(filtered);
        }
        else {
            if (sector.length === 0) {
                setFilteredItems(items);
            }
            else {
                setIsLoaded(false);
                var company_ids = getCompaniesFromSector();
                filtered = items.filter((item) => { return company_ids.includes(item.company_id) });
                setIsLoaded(true);
                setFilteredItems(filtered);
            }
        }

    }, [region]);

    useEffect(() => {
        if (sector.length !== 0) {
            if (region.length !== 0 && region !== 'United Kingdom') {
                setIsLoaded(false);
                var company_ids = getCompaniesFromSector();
                filtered = items.filter((item) => { return item.region === region && company_ids.includes(item.company_id) });
                setIsLoaded(true);
                setFilteredItems(filtered);
            }
            else {
                setIsLoaded(false);
                var company_ids = getCompaniesFromSector();
                filtered = items.filter((item) => { return company_ids.includes(item.company_id) });
                setIsLoaded(true);
                setFilteredItems(filtered);
            }
        }
        else {
            if (region.length !== 0 && region !== 'United Kingdom') {
                filtered = items.filter((item) => { return item.region === region });
                setFilteredItems(filtered);
            }
            else {
                setFilteredItems(items);
            }
        }
    }, [sector]);



    const getCompaniesFromSector = () => {
        var filteredCompanies = sectorItems.filter((item) => item.sector === sector);
        return Object.values(filteredCompanies).map(company => company.company_id);
    };



    const fetchAPIData = () => {
        console.log('fetching API data');
        fetch(primary_url)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.data);
                    setFilteredItems(result.data);
                    localStorage.setItem('scaleUpIndex', JSON.stringify(result.data));

                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

        fetch(secondary_url)
            .then(res => res.json())
            .then(
                (result) => {
                    setSectorItems(result.data);
                    localStorage.setItem('scaleUpIndexSectors', JSON.stringify(result.data)); // running out memory space for this!
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }



    if (filteredItems.length) {
        countScaleUps = filteredItems.length.toLocaleString('en-GB', { minimumFractionDigits: 0 });
        countEmployment = filteredItems.map(item => Number(item.employees)).reduce((a, b) => a + b).toLocaleString('en-GB', { minimumFractionDigits: 0 });
        countTurnover = filteredItems.map(item => Number(item.turnover)).reduce((a, b) => (a + b));
        countGrants = filteredItems.map(item => Number(item.grants)).reduce((a, b) => (a + b));
        countInvestment = filteredItems.map(item => Number(item.fundraising)).reduce((a, b) => (a + b));
    }


    const refreshData = () => {
        setIsLoaded(false);
        localStorage.removeItem("scaleUpIndex");
        localStorage.removeItem("scaleUpIndexSectors");
        //refetch
        fetchAPIData();
    }



    useEffect(() => {
        //try to load from local store.
        if (window.localStorage !== undefined) {
            var index_data = {};
            var sector_data = {};


            index_data = window.localStorage.getItem('scaleUpIndex');
            sector_data = window.localStorage.getItem('scaleUpIndexSectors');


            if (index_data !== null) {
                var list = JSON.parse(index_data);
                setItems(list);
                setFilteredItems(list);
            }

            if (sector_data !== null) {
                setSectorItems(JSON.parse(sector_data));
            }


        }
        setCheckLocalStore(true);
    }, []);


    useEffect(() => {
        //once local store is checked, fetch from API if required.
        if (items.length && sectorItems.length) {
            //console.log(items);
            setIsLoaded(true);
        }
        else {
            if (checkLocalStore) {
                fetchAPIData();
            }

        }

    }, [checkLocalStore])
    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Visible</span> Scaling Businesses</div>
                    <span className="source">Source: Scaleup Index 2023</span>
                </h2>
                <Spinner animation="border" variant="primary" />
                <p>Loading data...</p>
            </div>
        );
    }
    else {
        return (

            <div className="border-bottom mb-4 pb-4">
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Visible</span> Scaling Businesses</div>
                    <span className="source">Source: Scaleup Index 2023</span>
                </h2>

                <Row className="mb-2 align-items-center gy-3">
                    <Col xs="6" md="4">
                        <Card text="dark" className="text-center h-100 border-end border-primary rounded-0">
                            <Card.Body>
                                <Scaleups />
                                <Card.Title>Scaleups</Card.Title>
                                <Card.Text className="text-orange">
                                    {countScaleUps}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="6" md="4">
                        <Card text="dark" className="text-center h-100">
                            <Card.Body>
                                <Employment />
                                <Card.Title>Employment</Card.Title>
                                <Card.Text className="text-orange">
                                    {countEmployment}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="6" md="4">
                        <Card text="dark" className="text-center h-100  border-start border-primary rounded-0">
                            <Card.Body>
                                <Turnover />
                                <Card.Title>Turnover</Card.Title>
                                <Card.Text className="text-orange">
                                    {conditonalFormatNumber(countTurnover)}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Col>
                
                    <Col xs="6" md="6">
                        <Card text="dark" className="text-center h-100  border-end border-primary rounded-0">
                            <Card.Body>
                                <Grants />
                                <Card.Title>Amount of Grants</Card.Title>
                                <Card.Text className="text-orange">
                                    {conditonalFormatNumber(countGrants)}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col  xs="12" md="6">
                        <Card text="dark" className="text-center h-100">
                            <Card.Body>
                                <Investment />
                                <Card.Title>Amount of Equity Investment</Card.Title>
                                <Card.Text className="text-orange">
                                    {conditonalFormatNumber(countInvestment)}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </div>
        );
    }

}