
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Logo } from "./logo.svg";
import { refreshAllData } from './Utils';

export const Filters = (props) => {


  return (
    <Navbar className="navbar-dark bg-dark w-100 px-4">

      <Form className="w-100 align-items-center gy-3" as={Row}>


          <Col xs={12} md="auto">
            <a className="navbar-brand me-md-4" href="https://dashboard.scaleupinstitute.org.uk"><Logo /></a>
          </Col>
          <Col xs={12} md="auto">
            <h1 className="dashboard">
              <span><span className="emph">Scaleup</span> Cluster Dashboard</span>
            </h1>
          </Col>
          <Col xs={12} md="auto">
            <Button variant="outline-light" onClick={refreshAllData} className="align-self-center refresh-data">Refresh</Button>
          </Col>
          <Col xs={12} md="auto">
            <Row>
              <Col xs={6}>
                <Filter setRegion={props.setRegion} />
              </Col>
              <Col xs={6}>
                <Filter setSector={props.setSector} />
              </Col>
            </Row>
            
          </Col>

          <Col xs={12} md="auto">
            <Button variant="primary" onClick={(e) => {
              e.preventDefault();
              window.location.href = 'https://www.scaleupinstitute.org.uk/';
            }}>Back to Site</Button>
          </Col>



      </Form>
    </Navbar>

  );

}


const Filter = (props) => {
  const [checkLocalStore, setCheckLocalStore] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  var url;
  if (props.setRegion) {
    url = 'https://api.retool.com/v1/workflows/ac26db7b-fd53-4603-b62b-03b99b5c64cc/startTrigger?workflowApiKey=retool_wk_809e57ccd4ac4df081a288407fe88441&cache_bust=' + Date.now();
  }
  else {
    url = 'https://api.retool.com/v1/workflows/ae921b10-7465-43ae-8967-a4311a22f477/startTrigger?workflowApiKey=retool_wk_32a94f21543244bca781cd5b02c6e92f&cache_bust=' + Date.now();
  }



  const refreshData = () => {
    let localStoreName = '';
    if (props.setRegion) {
      localStoreName = 'regions';
    }
    else {
      localStoreName = 'sectors';
    }
    localStorage.removeItem(localStoreName);
    setIsLoaded(false);
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.data);
          localStorage.setItem(localStoreName, JSON.stringify(result.data));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )

  };


  useEffect(() => {
    if (window.localStorage !== undefined) {
      var data = {};
      if (props.setRegion) {
        data = window.localStorage.getItem('regions');

      }
      else {
        data = window.localStorage.getItem('sectors');
      }

      if (data !== null) {
        setItems(JSON.parse(data));
      }

    }
    setCheckLocalStore(true);
  }, []);


  useEffect(() => {
    if (items.length !== 0) {
      setIsLoaded(true);
    }
    else {
      if (checkLocalStore) {
        fetch(url)
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setItems(result.data);
              if (props.setRegion) {
                localStorage.setItem('regions', JSON.stringify(result.data));
              }
              else {
                localStorage.setItem('sectors', JSON.stringify(result.data));
              }
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }

    }

  }, [checkLocalStore])


  if (error) {
    return <div>Error: {error.message}</div>;
  }
  else if (!isLoaded) {
    return <div className="d-flex justify-content-start align-items-center text-light"><Spinner animation="border" variant="primary" className="me-4" />Loading...</div>;
  }
  else {
    if (props.setRegion) {
      return (
        <div className="d-md-flex justify-content-between align-items-center text-start">
          <Form.Label className="text-light  me-3 mb-0">Region</Form.Label>
          <Form.Select
            onChange={(e) => {
              props.setRegion(e.target.value);
            }}
            className="region-select"
            aria-label="Filter Results By Region">
            <option key="0" value="United Kingdom">United Kingdom</option>
            {items.map(item => (
              <option key={item.id} value={item.region_name}>{item.region_name}</option>
            ))}

          </Form.Select>
        </div>
      );
    }
    else {
      return (
        <div className="d-md-flex justify-content-between align-items-center text-start">
          <Form.Label className="text-light me-3 mb-0">Sector</Form.Label>
          <Form.Select
            onChange={(e) => {
              props.setSector(e.target.value);
            }}
            className="sector-select"
            aria-label="Filter Results By Sector">
            <option key="0" value="">All Sectors</option>
            {items.map(item => (
              <option key={item.id} value={item.sector_name}>{item.sector_name}</option>
            ))}
          </Form.Select>
        </div>


      );
    }

  }
}