import React, {useState} from 'react';
import {Filters} from './Filters';
import {ONSScaleUpData} from './ONSScaleUpData';
import {ScaleUpIndex} from './ScaleUpIndex';
import {SurveyData} from './SurveyData';
import {Clusters} from './Clusters';
import {ScaleupProgrammes} from './ScaleupProgrammes';
import {ProgMap} from './ProgMap';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function Dashboard(){
    const [region, setRegion] = useState([]); 
    const [sector, setSector] = useState([]); 
    const [debug, setDebug] = useState(false);
    return (
        <div>
            <Filters setRegion={setRegion} setSector={setSector} setDebug={setDebug} />
        <Container fluid className="g-0">            
            

            <Row className="g-0">
                <Col lg="6" className="pt-3 pb-4 px-4">
                    <ONSScaleUpData region={region} sector={sector} debug={debug} />
                    <ScaleUpIndex region={region} sector={sector} debug={debug} />
                    <SurveyData region={region} sector={sector} debug={debug} />
                </Col>
                <Col lg="6">
                    <ProgMap region={region} sector={sector} debug={debug} />
                </Col>
                <Col lg="6" className="text-bg-light pt-3 pb-4 px-4">
                    <Clusters region={region} sector={sector} debug={debug} />
                </Col>
                <Col lg="6" className="text-bg-light  pt-3 pb-4 px-4">
                    <ScaleupProgrammes region={region} sector={sector} debug={debug} />
                </Col>
            </Row>

            

        </Container>
        </div>
        
        
    );
}