import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { conditonalFormatMillions } from './Utils';
import { ReactComponent as Scaleups } from "./assets/scaleups-green.svg";
import { ReactComponent as Employment } from "./assets/employment-green.svg";
import { ReactComponent as Turnover } from "./assets/turnover-green.svg";


export const ONSScaleUpData = ({ region, sector, debug }) => {
    const [checkLocalStore, setCheckLocalStore] = useState(false);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    
    const url = 'https://api.retool.com/v1/workflows/59d261b5-3bde-41af-b7c6-0712868a94b3/startTrigger?workflowApiKey=retool_wk_5b3757fa681d4925b8302aacff6f9033&cache_bust='+Date.now();

    const tech_sector = 'Tech, Information & digital Activities (incl telecoms, computer programming, data processing, information services, software publishing, etc.)';
    var filtered;
    var countScaleUps = 0;
    var countEmployment = 0;
    var countTurnover = 0;

    var opts = { minimumFractionDigits: 0 };

    useEffect(() => {
        if (region.length !== 0) {
            
            filtered = items.filter((item) => {
                if (sector.length === 0) {
                    return item.region === region && item.sector !== tech_sector;
                }
                else {
                    return item.region === region && item.sector === sector;
                }
            });
            setFilteredItems(filtered);
        }
        else {
            if (sector.length !== 0) {
                filtered = items.filter((item) => item.sector === sector && item.region === 'United Kingdom');
                setFilteredItems(filtered);
            }
            else {
                filtered = items.filter((item) => item.region === 'United Kingdom' && item.sector !== tech_sector)
                //console.log(filtered);
                setFilteredItems(filtered);
            }

        }
    }, [region]);

    useEffect(() => {
        if (sector.length !== 0) {
            filtered = items.filter((item) => {
                if (region.length === 0) {
                    return item.sector === sector && item.region === 'United Kingdom';
                }
                else {
                    return item.region === region && item.sector === sector;
                }
            });
            setFilteredItems(filtered);
        }
        else {
            if (region.length !== 0) {
                filtered = items.filter((item) => item.region === region && item.sector !== tech_sector);
                setFilteredItems(filtered);
            }
            else {
                filtered = items.filter((item) => item.region === 'United Kingdom' && item.sector !== tech_sector)
                setFilteredItems(filtered);
            }
        }
    }, [sector]);



    if (filteredItems.length) {
        countScaleUps = filteredItems.map(item => item.subtotal_count).reduce((a, b) => a + b).toLocaleString('en-GB', opts);
        countEmployment = filteredItems.map(item => item.subtotal_emp).reduce((a, b) => a + b).toLocaleString('en-GB', opts);
        countTurnover = filteredItems.map(item => item.subtotal_tur).reduce((a, b) => (a + b));
    }


    useEffect(() => {
        if (window.localStorage !== undefined) {
          var data = {};
         
            data = window.localStorage.getItem('ONSScaleUpData');
        
  
          if (data !== null){

            var list = JSON.parse(data);
            var uk = list.filter((item) => item.region === 'United Kingdom' && item.sector !== tech_sector);
            
            setItems(list);
            setFilteredItems(uk);
          }
         
        }
        setCheckLocalStore(true);
      }, []);


    useEffect(() => {
        if (items.length !== 0){
            setIsLoaded(true);
          }
        else{
            if(checkLocalStore){
                fetch(url)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setItems(result.data);
                        var list = result.data;
                        var uk = list.filter((item) => item.region === 'United Kingdom' && item.sector !== tech_sector);
                        setFilteredItems(uk);
                        localStorage.setItem('ONSScaleUpData', JSON.stringify(result.data));
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
            }
           
        }
       
    }, [checkLocalStore])

    const refreshData = () => {
        
        setIsLoaded(false);
        //delete local store
        localStorage.removeItem("ONSScaleUpData");
        fetch(url)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setItems(result.data);
                        var list = result.data;
                        var uk = list.filter((item) => item.region === 'United Kingdom');
                        setFilteredItems(uk);
                        localStorage.setItem('ONSScaleUpData', JSON.stringify(result.data));
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )


    };



    if (error) {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded) {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Scaleup</span> Landscape</div>
                    <span className="source">Source: ONS IDBR 2018 - 2021</span>
                </h2>
                <Spinner animation="border" variant="primary" />
                <p>Loading data...</p>
            </div>
        );
    }
    else {
        return (
            <div>
                <h2 className="dashboard d-flex justify-content-between align-items-center">
                    <div><span className="emph">Scaleup</span> Landscape</div>
                    <span className="source">Source: ONS IDBR 2018 - 2021</span>
                </h2>
                
                <Row className="mb-4 pb-4 border-bottom align-items-center gy-3">
                    <Col xs="6" md="4">
                        <Card text="dark" className="text-center h-100 border-end border-success rounded-0">
                            <Card.Body>
                                <Scaleups />
                                <Card.Title>Scaleups</Card.Title>
                                <Card.Text className="text-green">
                                    {countScaleUps}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="6" md="4">
                        <Card text="dark" className="text-center h-100">
                            <Card.Body>
                                <Employment />
                                <Card.Title>Employment</Card.Title>
                                <Card.Text className="text-green">
                                    {countEmployment}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card text="dark" className="text-center h-100 border-start  border-success rounded-0">
                            <Card.Body>
                                <Turnover />
                                <Card.Title>Turnover</Card.Title>
                                <Card.Text className="text-green">
                                    {conditonalFormatMillions(countTurnover)}
                                </Card.Text>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>

                {debug &&
                    
                    <Table striped bordered hover>
                        <thead>
                            <tr><th>Region</th><th>Sector</th><th>Count</th><th>Employment</th><th>Turnover</th></tr>
                        </thead>
                        <tbody>
                            {filteredItems.map(item => (
                                <tr key={item.id}>
                                    <td>{item.region}</td>
                                    <td>{item.sector}</td>
                                    <td>{item.subtotal_count}</td>
                                    <td>{item.subtotal_emp}</td>
                                    <td>{item.subtotal_tur}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
            </div>
        );
    }





}