import { Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';

export const BarChart = ({ chartData }) => {
    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        fill: false,
        scales: {
            y: {
                grid: {
                    display: false
                  },
            },
            x: {
               
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return value + '%';
                    }
                }
            },
        },
        backgroundColor: [
            '#2D8E7A',
            '#FF8400',
            '#267CA0',
          ],
          borderColor: [
            '#2D8E7A',
            '#FF8400',
            '#267CA0',
          ],
        plugins: {
            tooltip: {
                backgroundColor: '#fff',
                bodyColor: '#373535',
                titleColor: '#383535',
                TitleFont: {
                    weight: '500'
                 },
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.x !== null) {
                            label += context.parsed.x + '%';
                        }
                        return "  "+label;
                    },
                    labelColor: function(context) {
                        return {
                            borderColor: context.element.options.borderColor,
                            backgroundColor: context.element.options.backgroundColor,
                            borderWidth: 4,
                            borderRadius: 0,
                        };
                    },
                }
            },
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Horizontal Bar Chart',
            },
        },
    };


    return (
        <div className="chart-container">
            <Bar
                datasetIdKey='id'
                options = {options}
                data={chartData}
            />
        </div>
    );
};